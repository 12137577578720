.touch-control {
    display: flex;
    flex-direction: row;
    height: 35vh;
    width: 100%;
}

.previous {
    margin: 8px;
    font-size: 24px;
    flex: 1 1 auto;
}

.next {
    margin: 8px;
    font-size: 24px;
    flex: 0 1 70%;
}
