.test-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    margin: 20px 0;
}

.row {
    display: flex;
    flex-direction: row;
    margin: 20px;
}
